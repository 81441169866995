// Import Core
import React, { useEffect } from "react";
import styled from "styled-components";
// Import Libraries
import { StaticImage } from "gatsby-plugin-image";
// Import Components
import Sponsors from "../../blocks/Sponsors";
import MobileMenuBanner from "../banners/MobileMenuBanner";
import Button from "../../ui/buttons/Button";
// Import Assets
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// Import Utils
import {
  GetDetailedListLinks,
  GetListLinks,
  GetTextButtonLinks,
} from "../../../utils/getNavItemLinks";
import useTablet from "../../../utils/useTablet";
import { getSlug } from "../../../utils/getSlug";
import { Link } from "gatsby";

const MenuSubMenuItemStyling = styled.div`
  .sub-menu-item-content {
    background-color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .sub-menu-item-content-title {
      display: flex;
      justify-content: center;
      color: var(--red);
      h6 {
        text-transform: uppercase;
      }
    }

    .sub-menu-section-title {
      display: flex;
      flex-direction: column;
      gap: 20px;
      h6 {
        color: var(--red);
      }
      .content {
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;
      }
    }

    .sub-menu-items {
      columns: 2;
      column-gap: 32px;

      font-size: var(--body-font-size);
      text-transform: capitalize;

      li {
        padding: 8px 0;
        -webkit-column-break-inside: avoid;
        a {
          color: var(--black);
          transition: var(--basic-transition);
          &:hover {
            color: var(--red);
          }
        }
      }
      &:has(.detailed-list-item) {
        columns: 3;
      }
      .detailed-list-item {
        a {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          .gatsby-image-wrapper {
            height: 90px;
            width: 100px;
            aspect-ratio: 16 / 9;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain !important;
            }
          }
          .detailed-list-item-content {
            display: flex;
            flex-direction: column;
            padding: 8px 0;
            gap: 8px;
          }
        }
      }
    }
  }
  /* Mobile */
  &.mobile-menu-list-item {
    .sub-menu-item-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      svg {
        cursor: pointer;
        color: var(--neutral-75);
        height: 20px;
        width: 20px;
      }
    }
    .sub-menu-item-content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      .sub-menu-item-content-title {
        justify-content: center;
        padding: 12px 0;
        border-bottom: 1px solid var(--grey-10);
        .left,
        .right {
          flex: 1;
          svg {
            cursor: pointer;
          }
        }
      }
      .sub-menu-section-title {
        padding: 16px 0 24px;
        border-bottom: 1px solid var(--grey-10);
        .content {
          @media (max-width: 768px) {
            flex-direction: column;
            gap: 24px;
          }
        }
      }
      .sub-menu-items {
        padding: 8px 0;
        column-rule: 1px solid var(--grey-10);
        @media (max-width: 768px) {
          columns: unset;
        }
        li {
          border-bottom: 1px solid var(--grey-10);
        }
      }
    }
  }
  /* Desktop */
  &.menu-list-item {
    .sub-menu-item-title {
      cursor: pointer;
      white-space: nowrap;
      transition: var(--basic-transition);
      &:hover {
        color: var(--red);
      }
      svg {
        display: none;
      }
    }
    .sub-menu-item-content {
      padding: 32px 64px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      &.three-col {
        /* display: block; */
        flex-direction: row;
        /* column-gap: 64px; */
        gap: 0;
        > div {
          padding: 0 32px;

          /* column-rule: 1px solid var(--grey-10); */
          flex-basis: calc(100% / 3);
          &:not(:nth-child(3)) {
            border-right: 1px solid var(--grey-10);
          }
          &:first-child {
            padding-left: 0;
          }
          &:nth-child(3) {
            padding-right: 0;

            /* img { */
            width: calc(100% - 32px);
            /* } */
          }
        }
      }
      .sub-menu-item-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .sub-menu-item-content-title {
        justify-content: start;
        padding: 0;
        .left,
        .right {
          svg {
            display: none;
          }
        }
        &:has(+ .sub-menu-section-title) {
          display: none;
        }
      }
      .sub-menu-section-title {
        padding: 0 0 24px;
      }
      .sub-menu-items {
        padding: 0;
      }
    }
  }
`;

const MenuSubMenuItem = ({
  title,
  items,
  detailedSubMenu,
  sectionLink,
  sectionContent,
  isActiveNavItem,
  index,
  activeIndex,
  handleClick,
  className,
  closeHamburger,
}) => {
  const { isTablet } = useTablet();

  // Calculate height for mega menu
  const handleResize = () => {
    const height = document.getElementsByTagName("header")[0].clientHeight;

    if (!isTablet) {
      if (
        document.querySelectorAll(".menu-list-item .sub-menu-item-content")[0]
      ) {
        // Take height of header & height of searchbar
        const calcHeight = height - 72;
        document.querySelectorAll(
          ".menu-list-item .sub-menu-item-content"
        )[0].style.top = height + "px";
        document.querySelectorAll(
          ".menu-list-item .sub-menu-item-content"
        )[0].style.height = "calc(100vh - " + calcHeight + "px)";
      }
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
  }, [isTablet, activeIndex]);

  return (
    <MenuSubMenuItemStyling className={`sub-menu-item ${className}`}>
      <div className="sub-menu-item-title" onClick={() => handleClick(index)}>
        <div className="nav-item">{title}</div>
        <div className="open-sub-menu">
          {index === activeIndex ? <FaChevronLeft /> : <FaChevronRight />}
        </div>
      </div>

      {/* Show sub menu */}
      {/* TODO:  Add animation */}
      {index === activeIndex && (
        <div
          className={`sub-menu-item-content${
            !detailedSubMenu ? " three-col" : ""
          }`}
        >
          <div className="sub-menu-item-content-wrapper">
            <div className="sub-menu-item-content-title">
              <div className="left">
                <FaChevronLeft onClick={() => handleClick(null)} />
              </div>
              <h6>{title}</h6>
              <div className="right"></div>
            </div>

            {sectionContent && (
              <div className="sub-menu-section-title">
                <h6>{title}</h6>
                <div className="content">
                  <div>
                    {sectionLink.navItemUrl.internalLink ? (
                      <Link to={getSlug(sectionLink.navItemUrl.internalLink)}>
                        <Button
                          size="md"
                          btnStyle="outline"
                          className=""
                          value="View All Destinations"
                          clickHandler={() => {
                            handleClick(null);
                            closeHamburger();
                          }}
                          isDisabled={false}
                        />
                      </Link>
                    ) : (
                      <a
                        href={sectionLink.navItemUrl.externalUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          size="md"
                          btnStyle="outline"
                          value="View All Destinations"
                          clickHandler={() => {
                            handleClick(null);
                            closeHamburger();
                          }}
                          isDisabled={false}
                        />
                      </a>
                    )}
                  </div>
                </div>
                <h6>Our Most Popular Destinations:</h6>
              </div>
            )}
            <ul className="sub-menu-items">
              {/* If detailed - we need to get more info - image etc */}
              {detailedSubMenu
                ? items.items.map((item, index) => (
                    <GetDetailedListLinks
                      item={item}
                      index={index}
                      key={index}
                      className="detailed-list-item"
                      handleClick={() => {
                        handleClick(null);
                        closeHamburger();
                      }}
                    />
                  ))
                : items.items.map((item, index) => (
                    <GetListLinks
                      item={item}
                      index={index}
                      key={index}
                      handleClick={() => {
                        handleClick(null);
                        closeHamburger();
                      }}
                    />
                  ))}
            </ul>
          </div>

          {!detailedSubMenu && !isTablet && <Sponsors color="default" />}
          {!detailedSubMenu && !isTablet && (
            <div className="placeholder-image">
              <StaticImage
                src="../../../assets/images/watercolours/Dijver_Canal_Bruges_Belgium.jpg"
                alt=""
                objectFit="contain"
              />
            </div>
          )}
          <MobileMenuBanner />
        </div>
      )}
    </MenuSubMenuItemStyling>
  );
};

export default MenuSubMenuItem;
