// Import Core
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// Import Libraries
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// Import Components
import Sponsors from '../blocks/Sponsors';
import SocialIcons from '../blocks/SocialIcons';
// Import configData
import configData from '../../../configData.json';
// Import Utils
import { GetListLinks } from '../../utils/getNavItemLinks';

const FooterStyling = styled.footer`
	background: var(--red-10);
	color: var(--red);
	display: flex;
	padding: 64px;
	flex-direction: column;
	align-items: center;
	gap: 80px;
	align-self: stretch;
	margin-bottom: 72px;
	width: 100%;

	@media (max-width: 992px) {
		gap: 48px;
	}
	@media (max-width: 768px) {
		padding: 40px 20px;
	}

	.footer-top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 32px;
		width: 100%;
		color: var(--black);
		@media (max-width: 992px) {
			flex-wrap: wrap;
			row-gap: 48px;
		}
		.title {
			text-transform: uppercase;
			font-family: var(--font-family-heading);
			color: var(--red-110);
		}
		.left {
			display: flex;
			flex-direction: column;
			gap: 24px;
			order: 1;
			@media (max-width: 992px) {
				order: 2;
				max-width: calc(50% - 16px);
			}
			.address,
			.call,
			.contact {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}
		}
		.middle {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 40px;
			order: 2;
			@media (max-width: 992px) {
				order: 1;
				width: 100%;
			}
			.logo {
				height: fit-content;
				img {
					height: 100px;
					width: auto;
				}
			}
		}
		.right {
			order: 3;
			text-align: right;
			@media (max-width: 992px) {
				order: 3;
				max-width: calc(50% - 16px);
			}
			.secondary-menu {
				margin-top: 12px;
				gap: 12px;
				display: flex;
				flex-direction: column;
				@media (max-width: 992px) {
					margin-top: 4px;
					gap: 4px;
				}
				li {
					text-transform: uppercase;
					a{
						p{
							font-family: var(--font-family-heading);
						}
					}
				}
			}
		}
	}
	.footer-bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 32px;
		width: 100%;
		padding-top: 32px;
		border-top: 1px solid var(--red-75);
		@media (max-width: 992px) {
			flex-direction: column-reverse;
			align-items: flex-start;
		}
		.left {
			display: flex;
			gap: 24px;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 992px) {
				flex-direction: column-reverse;
				align-items: flex-start;
				gap: 32px;
			}
			.footer-menu-list {
				@media (max-width: 992px) {
					max-width: 350px;
					columns: 2;
				}
				.menu {
					display: flex;
					flex-flow: row wrap;
					gap: 24px;
					justify-content: center;
					align-items: center;
					@media (max-width: 992px) {
						display: flex;
						flex-flow: row wrap;
						gap: 16px 32px;
						justify-content: flex-start;
					}
					li {
						text-decoration: underline;
					}
				}
			}
		}
		.right {}
	}
`;

const Footer = ({ logo, socialLinks, footerMenu, footerSecondaryMenu }) => {
	return (
		<FooterStyling>
			<div className='footer-top'>
				<div className='left'>
					<div className='address'>
						<p className='title small'>Address</p>
						<address>
							<p>{configData.contact.address.name},</p>
							<p>{configData.contact.address.line_one},</p>
							<p>{configData.contact.address.line_two},</p>
							<p>{configData.contact.address.city},</p>
							<p>{configData.contact.address.postcode}</p>
						</address>
					</div>
					<div className='call'>
						<p className='title small'>Call</p>
						<p>{configData.contact.phone}</p>
					</div>
					<div className='contact'>
						<p className='title small'>Office Hours</p>
						<p>{configData.contact.office_hours.line_one}</p>
						<p>{configData.contact.office_hours.line_two}</p>
						<p>{configData.contact.office_hours.line_three}</p>
					</div>
				</div>
				<div className='middle'>
					<Link to='/' className='logo'>
						<GatsbyImage image={getImage(logo)} alt='Kirker Holidays' />
					</Link>
					<Sponsors color='dark' />
				</div>
				<div className='right'>
					<p className='title small'>{footerSecondaryMenu.title}</p>
					<ul className='secondary-menu small'>
						{!footerSecondaryMenu.displayList &&
							footerSecondaryMenu.items.map((menu, index) => {
								return <GetListLinks item={menu} index={index} key={index} />;
							})}
					</ul>
				</div>
			</div>
			<div className='footer-bottom'>
				<div className='left'>
					<p className='small'>
						&copy; {new Date().getFullYear()} Kirker Holidays. All rights reserved. Registered in England: 1985696. ATOL 2450. ABTA V6595.
					</p>
					<div className='footer-menu-list'>
						<ul className='menu small'>
							{!footerMenu.displayList &&
								footerMenu.items.map((menu, index) => {
									return <GetListLinks item={menu} index={index} key={index} />;
								})}
						</ul>
					</div>
				</div>
				<div className='right'>
					<SocialIcons socialLinks={socialLinks} />
				</div>
			</div>
		</FooterStyling>
	);
};

export default Footer;
