import React from "react";
import Layout from "./src/components/Layout";
import AuthLayout from "./src/components/AuthLayout";
import axios from "axios";

// gatsby-ssr is a direct copy of gatsby-browser.js
// Gatsby Browser runs once page has been loaded
// Gatsby SSR is server side render

// Wrap Page in Layout Component - standard across all pages
const wrapPageElement = ({ element, props }) => {
  if (props.location.pathname === "/sign-in/") {
    return <AuthLayout {...props}>{element}</AuthLayout>;
  } else {
    return <Layout {...props}>{element}</Layout>;
  }
};

export { wrapPageElement };

export function onRouteUpdate({ location, prevLocation }) {
  if (window.location.hash === "" && window.location.search === "")
    window.scroll({ top: 0, left: 0, behavior: "instant" });

  if (location.pathname.includes("/destinations/"))
    document.querySelector(".get-in-touch-btn").style.display = "none";
  // else  document.querySelector(".get-in-touch-btn").style.display = "block";

  const netlifyFunctionUrl = "/.netlify/functions/collect";

  axios
    .get(netlifyFunctionUrl)
    .then((response) => {
      console.log("Recorded:");
    })
    .catch((error) => {
      console.error("Error recording:", error);
    });
}
